import AutoPopup from "@components/AutoPopup/AutoPopup"
import BannerHome from "@components/Banner/BannerHome"
import Layout from "@components/Layout/Layout"
import Seo from "@components/seo"
import loadable from "@loadable/component"
import "@styles/main.scss"
import { graphql } from "gatsby"
import React from "react"
import { useLocation } from "@reach/router";
import ContentLinks from "@components/ContentLinks/ContentLinks"
// import AdsBanner from "@components/AdsBanner/AdsBanner"
// import QuestionnaireBanner from "@components/QuestionnaireBanner/QuestionnaireBanner"
import FeaturedPropertiesSlider from "@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
import SignaturePropertiesSlider from "@components/SignaturePropertiesSlider/SignaturePropertiesSlider"
import DubaiCommunities from "@components/DubaiCommunities/DubaiCommunities"
import NewsSlider from "@components/NewsSlider/NewsSlider"
import DeveloperSlider from "@components/DeveloperSlider/DeveloperSlider"
import OurPartner from "@components/OurPartner/OurPartner"
// import ReviewSlider from "@components/ReviewSlider/ReviewSlider"
import TileBlockAds from "@components/TileBlockAds/TileBlockAds"
import useInteractive from "@hooks/useInteractive"
// const DubaiCommunities = loadable(() => import("@components/DubaiCommunities/DubaiCommunities"))
// const FeaturedPropertiesSlider = loadable(() => import("@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"))
// const SignaturePropertiesSlider = loadable(() => import("@components/SignaturePropertiesSlider/SignaturePropertiesSlider"))
const ContactFomModule = loadable(() =>
  import("@components/ContactFomModule/ContactFomModule")
)
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))
const HomeTemplate = ({ data }) => {
  const { hasInteracted } = useInteractive()
  const pageData = data?.strapiPage
  const modules = pageData?.modules
  const banner = pageData?.banner
  // const imagetransforms = pageData?.imagetransforms
  const strapiID = pageData?.strapi_id
  const location = useLocation();
  const open = location.state?.open || false;
  return (
    <Layout 
    transparentHeader pageData={pageData}>
      <div className="home-banner">
      <BannerHome
        bannerData={banner}
        ggfx_results={pageData?.ggfx_results}
        strapiID={strapiID}
      />
      </div>
     
      {hasInteracted && <AutoPopup />}
      {pageData?.video_ask_url && hasInteracted &&
<VideoAskWidget video_ask_url={pageData?.video_ask_url} />
}
      {modules?.map((module) => (
        <React.Fragment key={module.id}>
          {module?.strapi_component === "modules.content-and-links" && (
            <ContentLinks module={module} ggfx_results={pageData?.ggfx_results} 
            strapiID={strapiID} />
          )}
          {/* {module?.strapi_component === "modules.ads-banner" && (
            <AdsBanner module={module} />
          )} */}  
          {module?.strapi_component === "modules.tile-block" &&(
          <TileBlockAds module={module} ggfx_results={pageData?.ggfx_results} imagename={"page.image.tile_img_ads"}
          strapiID={strapiID} isAds/>
          )}
          {/* {module?.strapi_component === "modules.questionnaire" && (
          <QuestionnaireBanner  ggfx_results={pageData?.ggfx_results}
          strapiID={strapiID} bannerData={module} open={open}/>
          )} */}

          {module?.strapi_component === "modules.featured-prop-slider" &&
            !module?.is_signature && hasInteracted &&(
              <FeaturedPropertiesSlider module={module} />
            )}
          {module?.strapi_component === "modules.featured-prop-slider" &&
            module?.is_signature && hasInteracted &&(
              <SignaturePropertiesSlider
                module={module}
                ggfx_results={pageData?.ggfx_results}
                strapiID={strapiID}
              />
            )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "contact_module" && hasInteracted &&(
              <ContactFomModule module={module} />
            )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "dubai_communities" && (
              <DubaiCommunities module={module} />
            )}
          {module?.strapi_component === "modules.featured-news" && (
            <NewsSlider module={module} />
          )}
          {/* {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "reviews_slider" && (
              <ReviewSlider module={module} reviews={pageData?.reviews} />
            )} */}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "developer_slider" && hasInteracted &&(
              <DeveloperSlider module={module} />
            )}
             {module?.strapi_component === "modules.partner" && hasInteracted &&(
            <>
            <OurPartner
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
            </>
          )}
        </React.Fragment>
      ))}
      {/* <SignaturePropertiesSlider
        module={module}
        strapiID={strapiID}
        imagetransforms={imagetransforms}
      /> */}
    </Layout>
  )
}

export default HomeTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <>
        {/* <link rel="preload" fetchpriority="high" href="/images/home.webm" as="video" type="video/webm" />
        <link rel="preload" fetchpriority="high" as="image" href="/images/video-thumbnail.webp" type="image/webp" /> */}
        {/* <link rel="preload" fetchpriority="high" as="image" href="../images/video_thumbnail_mobile.webp" type="image/webp" /> */}
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
    </>
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_CONTENT_AND_LINKS {
          ...contentAndLinks
        }
        ... on STRAPI__COMPONENT_MODULES_ADS_BANNER {
          ...adsBanner
        }
        ... on STRAPI__COMPONENT_MODULES_GLOBAL_MODULE {
          ...globalModule
        }
        ... on STRAPI__COMPONENT_MODULES_TILE_BLOCK {
          ...tileBlock
        }
        ... on STRAPI__COMPONENT_MODULES_QUESTIONNAIRE {
          ...questionnaireFragment
        }
        ... on STRAPI__COMPONENT_MODULES_FEATURED_PROP_SLIDER {
          ...featuredProperties
        }
        ... on STRAPI__COMPONENT_MODULES_PARTNER {
          ...partner
        }
        ... on STRAPI__COMPONENT_MODULES_FEATURED_NEWS {
          ...featuredNews
        }
      }
    }
  }
`
